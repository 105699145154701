import React from 'react';
import { Link, graphql, StaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import styles from './Editor.module.scss';

export const PureEditor = ({ data, editor, isIndex }) => (
    <div className={styles['editor']}>
      <Link to="/">
        <p>Home</p>
        <Img fixed={data.imageOne.childImageSharp.fixed} />
      </Link>

      { isIndex ? (
        <h1 className={styles['editor__title']}>
          <Link className={styles['editor__title-link']} to="/">{editor.name}</Link>
        </h1>
      ) : (
        <h2 className={styles['editor__title']}>
          <Link className={styles['editor__title-link']} to="/">{editor.name}</Link>
        </h2>
      )}
      <p className={styles['editor__subtitle']}>{editor.bio}</p>
    </div>
);

export const Editor = (props) => (
  <StaticQuery
    query={ graphql`
      query {
        imageOne: file(relativePath: {eq: "editor.png"}) {
          childImageSharp {
            fixed(width: 128) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    `}
    render={(data) => <PureEditor {...props} data={data}/>}
  />
);

export default Editor;