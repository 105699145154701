import React from 'react';
import styles from './Copyright.module.scss';

const Copyright = ({ copyright, buildTime }) => (
  <div className={styles['copyright']}>
    {copyright}
    <br />
    Built: {buildTime}
  </div>
);

export default Copyright;